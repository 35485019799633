import axios from "../../utils/axios";

//
export function groupList(params) {
    return axios({
        url: "/admin/group/list",
        method: "get",
        params: params
    });
}

//
export function groupAll() {
    return axios({
        url: "/admin/group/all",
        method: "get"
    });
}

//
export function groupSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/group/add"
            : "/admin/group/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function groupDelete(data) {
    return axios({
        url: "/admin/group/delete",
        method: "post",
        data: data
    });
}
